<template>
  <b-sidebar
    id="address-sidebar"
    v-model="dataSideBar.status"
    bg-variant="white"
    right
    backdrop
    shadow
    no-header
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Müşteri Adresi
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <div class="p-2">
        <address-form />
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar } from 'bootstrap-vue'
import AddressForm from '@/views/Customers/View/CustomerAddress/AddressForm.vue'

export default {
  name: 'AddressSidebar',
  components: {
    BSidebar,
    AddressForm,
  },
  computed: {
    dataSideBar() {
      return this.$store.getters['customerAddresses/dataSideBar']
    },
  },
}
</script>
